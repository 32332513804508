import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Section from "../components/Section"
import Hero from "../components/Hero"

const AboutPage = ({ data }) => {
  if (!data) return null

  const pageData = data.prismicAbout || {}
  const { lang, type, url } = pageData || {}
  const alternateLanguages = pageData.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout activeDoc={activeDoc}>
      <Seo
        title={pageData.data.title.text}
        description={pageData.data.description.text}
      />
      <Hero data={pageData.data} />
      {pageData.data.body &&
        pageData.data.body.map(section => (
          <Section key={uuidv4()} data={section} />
        ))}
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageTemplateQuery($uid: String, $lang: String) {
    prismicAbout(uid: { eq: $uid }, lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
        url
      }
      lang
      url
      type
      data {
        title {
          text
          raw
        }
        description {
          text
        }
        hero_title {
          raw
        }
        hero_subtitle {
          raw
        }
        body {
          ... on PrismicAboutBodySectionStandard {
            slice_type
            primary {
              section_type
              heading {
                raw
              }
              subheading {
                raw
              }
              button_text {
                text
              }
              button_link {
                uid
                type
                lang
                url
                link_type
              }
            }
            items {
              column {
                raw
              }
            }
          }
          ... on PrismicAboutBodySectionCta {
            slice_type
            primary {
              heading {
                raw
              }
              text {
                raw
              }
              button_text {
                text
              }
              button_link {
                uid
                type
                lang
                url
                link_type
              }
              selected_subject {
                text
              }
            }
            items {
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
                thumbnails {
                  mobile {
                    alt
                    url
                  }
                }
              }
            }
          }
          ... on PrismicAboutBodySectionCards {
            slice_type
            primary {
              heading {
                raw
              }
              subheading {
                raw
              }
            }
            items {
              card_title {
                text
              }
              card_subtitle {
                text
              }
              card_content {
                text
              }
              card_link {
                uid
                type
                lang
                url
                link_type
              }
            }
          }
        }
      }
    }
  }
`
